@charset "UTF-8";
/*
  The error page CSS is compiled separately to the main styling, and sits in /public.
  It needs to have its own reset, etc..
*/
p, li {
  font-size: 1.8em;
}

p {
  margin-bottom: 0.5em;
}

a {
  position: relative;
  color: #009DFF;
}
a:after {
  content: " ";
  position: absolute;
  display: block;
  width: 100%;
  height: 2px;
  background: #008de6;
  top: 100%;
  left: 0;
  opacity: 0;
  transform: translateY(0.4em);
  transition: opacity 0.2s, transform 0.2s;
}
a:hover, a:focus {
  color: #008de6;
}
a:hover:after, a:focus:after {
  opacity: 1;
  transform: translateY(0px);
}

h1, h2, h3, h4, h5 {
  margin-bottom: 0.2em;
}
h1[data-short-text]:before, h2[data-short-text]:before, h3[data-short-text]:before, h4[data-short-text]:before, h5[data-short-text]:before {
  content: attr(data-short-text);
}
h1[data-short-text] span, h2[data-short-text] span, h3[data-short-text] span, h4[data-short-text] span, h5[data-short-text] span {
  display: none;
}
@media only screen and (min-width: 900px) {
  h1[data-short-text]:before, h2[data-short-text]:before, h3[data-short-text]:before, h4[data-short-text]:before, h5[data-short-text]:before {
    content: "";
  }
  h1[data-short-text] span, h2[data-short-text] span, h3[data-short-text] span, h4[data-short-text] span, h5[data-short-text] span {
    display: inline;
  }
}

h1 {
  font-size: 3em;
}

h2, h3 {
  font-size: 2.5em;
}

.subtitle {
  margin-bottom: 1em;
  font-size: 2.5em;
  color: #7d7d7d;
  font-weight: 600;
}

ul {
  list-style: none;
}
ul li {
  position: relative;
  margin-bottom: 0.3em;
  padding-left: 1em;
}
ul li:before {
  content: "•";
  position: absolute;
  top: 0.05em;
  left: 0;
}
@media only screen and (min-width: 600px) {
  ul.two-columns li {
    width: 45%;
    float: left;
  }
  ul.two-columns li:nth-child(even) {
    margin-left: 1em;
  }
  ul.two-columns li:nth-child(odd) {
    clear: both;
  }
}
@media only screen and (min-width: 900px) {
  ul.three-columns li {
    width: 30%;
    float: left;
  }
  ul.three-columns li:nth-child(even) {
    margin-left: 0;
  }
  ul.three-columns li:nth-child(odd) {
    clear: none;
  }
  ul.three-columns li:nth-child(3n-1), ul.three-columns li:nth-child(3n) {
    margin-left: 0.8em;
  }
  ul.three-columns li:nth-child(3n+1) {
    clear: both;
  }
}

* {
  margin: 0;
  padding: 0;
  outline: none;
}

html {
  height: 100%;
}

body {
  width: 100%;
  height: 100%;
  font-size: 10px;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch;
}

h1, h2, h3, h4, h5, p {
  widows: 3;
  orphans: 3;
}

a {
  text-decoration: none;
}

.hidden {
  width: 0;
  height: 0;
  margin: 0;
  overflow: hidden;
  display: block;
}

.clearfix:after {
  clear: both;
  height: 0;
  content: " ";
  font-size: 0;
  display: block;
}

::selection {
  background-color: #009DFF;
  color: #FFFFFF;
}

#header {
  width: 100%;
  background: #009DFF;
  margin-bottom: 1em;
  padding-top: 8px;
  padding-bottom: 8px;
  border-bottom: 1px solid #008de6;
  box-sizing: border-box;
}
#header a {
  width: 180px;
  height: 31px;
  background: url(../images/logos/clickmechanic.png) no-repeat;
  background-size: 100% auto;
  margin: 0 auto;
  display: block;
  background-image: url(../svgs/logos/clickmechanic.svg);
}
#header a:after {
  display: none;
}

body {
  background: #FFFFFF;
  font-family: "Open Sans", sans-serif;
}

#wrapper {
  min-height: 100%;
  position: relative;
  display: flex;
  flex-flow: column;
}

#content {
  flex: 1 1 0;
  display: flex;
  flex-flow: column;
}

.section {
  width: 100%;
  max-width: 1080px;
  position: relative;
  margin-right: auto;
  margin-left: auto;
  padding: 2em 14px;
  box-sizing: border-box;
}
p.section {
  margin-bottom: 0;
}

.section.no-vertical-padding {
  padding-top: 0;
  padding-bottom: 0;
}
.section:not(:last-child):not(:last-of-type) {
  margin-bottom: 1em;
}
@media only screen and (min-width: 600px) {
  .section {
    padding-right: 25px;
    padding-left: 25px;
  }
}

.zebra {
  background: #EDEDED;
}
.zebra .section {
  padding-top: 3em;
  padding-bottom: 3em;
}
.zebra .section.no-vertical-padding {
  padding-top: 0;
  padding-bottom: 0;
}

.main-section {
  flex: 1 1 auto;
}

.error-page #content .main-section {
  min-height: 30em;
}
@media only screen and (min-width: 730px) {
  .error-page #content .main-section {
    min-height: 18em;
  }
}
@media only screen and (min-width: 780px) {
  .error-page #content .main-section {
    min-height: 35em;
    padding-right: 48em;
  }
  .error-page #content .main-section:before {
    content: " ";
    position: absolute;
    display: block;
    width: 480px;
    height: 286px;
    background: url(../images/backgrounds/error-page_toy-car_large.jpg) no-repeat;
    background-size: 100% auto;
    top: 5em;
    right: 1em;
  }
  .error-page #content .main-section h1, .error-page #content .main-section p {
    position: relative;
    z-index: 10;
  }
}
@media only screen and (min-width: 840px) {
  .error-page #content .main-section:before {
    width: 500px;
    height: 298px;
    right: 2em;
  }
}
@media only screen and (min-width: 900px) {
  .error-page #content .main-section {
    padding-right: 54em;
  }
  .error-page #content .main-section p:not(.subtitle) {
    max-width: 90%;
  }
  .error-page #content .main-section:before {
    width: 536px;
    height: 320px;
    top: 4em;
    right: 3em;
  }
}
@media only screen and (min-width: 1280px) {
  .error-page #content .main-section {
    padding-right: 44em;
  }
  .error-page #content .main-section:before {
    right: -5em;
  }
}
@media only screen and (max-height: 679px) {
  .error-page #content .main-section:before {
    top: 3em;
  }
}
.error-page #content .zebra:not(.no-vertical-padding) {
  padding-bottom: 2em;
}
.error-page #content .zebra h2 {
  margin-bottom: 0.5em;
}
.error-page #content ul {
  margin-bottom: 2em;
}
@media only screen and (min-width: 400px) {
  .error-page #content ul.two-columns li {
    width: 40%;
    float: left;
  }
  .error-page #content ul.two-columns li:nth-child(even) {
    margin-left: 1em;
  }
  .error-page #content ul.two-columns li:nth-child(odd) {
    clear: both;
  }
}
@media only screen and (min-width: 600px) {
  .error-page #content ul.two-columns.three-columns li {
    width: 28%;
  }
  .error-page #content ul.two-columns.three-columns li:nth-child(even) {
    margin-left: 0;
  }
  .error-page #content ul.two-columns.three-columns li:nth-child(odd) {
    clear: none;
  }
  .error-page #content ul.two-columns.three-columns li:nth-child(3n-1), .error-page #content ul.two-columns.three-columns li:nth-child(3n) {
    margin-left: 0.8em;
  }
  .error-page #content ul.two-columns.three-columns li:nth-child(3n+1) {
    clear: both;
  }
}
.error-page #content .code {
  font-size: 1.4em;
  color: #7d7d7d;
}